///include /assets/js/plugins/network-property-set.js
///include /assets/js/app/s/checkout-info.js
///include /assets/js/app/p/user.js

"use strict"

/**
 *  A shared setting object
 */
class SharedSettings extends NetworkPropertySet {
    static get inst() {
        if(!this._inst) {
            this._inst = new SharedSettings()
        }
        return this._inst
    }

    /**
     *
     */
    constructor() {
        super()
        /** @type {?boolean} */
        this.authenticatedUser            = null;
        /** @type {?boolean} */
        this.canBuy = null
        /** @type {?boolean} */
        this.canCreateCustomerSupportTicket = null
        this.canUpdateContactOptions      = null;
        this.config                       = null;
        /**
         * @type {?GET.CustomerInformation.userContact.me.output}
         */
        this.contact                      = null;
        this.customerSupportTicketOptions = null;
        /** @type {?boolean} */
        this.hasBilling = null
        /** @type {?boolean} */
        this.isClient = null
        /** @type {?boolean} */
        this.isOpen = null
        this.ticketCount                  = null;

        this.user = User.inst
        this.userIdInfo                   = null;

        this.checkoutInfo = CheckoutInfo.inst;

        const cacheFields = [
            "authenticatedUser",
            "canBuy",
            "canCreateCustomerSupportTicket",
            "hasBilling",
            "isClient",
            "isOpen",
        ]
        for(const f of cacheFields) {
            if(sessionStorage.hasOwnProperty(`settings.${f}`)) {
                this[f] = !!+sessionStorage[`settings.${f}`]
                this.reload([f])
            }
        }
    }

    get avatarURL() {
        if( this.contact && this.contact.avatarUrl ){
            return this.contact.avatarUrl.replace("DOMAINPLACEHOLDER", window.location.origin );
        } else {
            return null;
        }
    }

    get brand() {
        return window.brand ? window.brand : {};
    }

    /**
     * @type {?boolean}
     */
    get canUpdateContact() {
        return(
            this.contact &&
            !!this.contact.person_name &&
            this.canUpdateContactOptions &&
            !!this.canUpdateContactOptions.canUpdateContact
        )
    }

    /**
     * @type {string}
     */
    get customHomeUrl() {
        if( this.isOpen && this.brand && this.brand.customHomeUrl ){
            return this.brand.customHomeUrl;
        } else {
            return "/";
        }
    }

    /**
     * @type {string}
     */
    get customVatLabel() {
        if( this.isOpen && this.brand && this.brand.customVatLabel ){
            return this.brand.customVatLabel;
        } else {
            return "VAT";
        }
    }

    get defaultClosedNavbar() {
        if( this.isOpen && this.brand && this.brand.togglePrefs ) {
            return !!this.brand.togglePrefs.defaultClosedNavbar;
        } else {
            return false;
        }
    }

    get networkPropertyHandlers() {
        return {
            authenticatedUser: () => this.preloadSingle("userIdInfo").then(
                r => !!(r && r.content),
                () => false
            ).then(v => this.cacheFlag("authenticatedUser", v)),
            canBuy: () => this.preloadSingle("userIdInfo").then(
                r => !r || (r.scope && r.scope.some(s => s.match(/^buy(=|$)/))),
                () => true
            ).then(v => this.cacheFlag("canBuy", v)),
            canCreateCustomerSupportTicket: () => this.preloadSingle("customerSupportTicketOptions").then(
                o => +!!o.canCreateCustomerSupportTicket
            ).then(v => this.cacheFlag("canCreateCustomerSupportTicket", v)),
            canUpdateContactOptions:() => $.ajax({method: "options", url: `/a/stack_contact/me`}),
            config: () => $.ajax(`/a/catalogue/config`).then(
                (r) => {
                    if( !r.prefs ){
                        r.prefs = {};
                    }
                    return r;
                }
            ),
            contact: () => $.ajax(`/a/stack_contact/me`).then(
                r => r,
                () => ({})
            ),
            customerSupportTicketOptions: () => $.ajax({method: "options", url: `/a/customer_support_ticket`}),
            hasBilling: () => this.preloadSingle("userIdInfo").then(
                r => !r || (r.scope && r.scope.some(s => s.match(/^billing(=|$)/))),
                () => true
            ).then(v => this.cacheFlag("hasBilling", v)),
            isClient: () => this.preloadSingle("contact").then(
                c => c && !!c.person_name
            ).then(v => this.cacheFlag("isClient", v)),
            isOpen: () => this.preloadSingle("config").then(
                c => c && !!c.isOpen
            ).then(v => this.cacheFlag("isOpen", v)),
            ticketCount: () => this.getTicketCount(),
            userIdInfo: () => this.user.preloadSingle("identityInfo"),
        }
    }

    /**
     * @type {?boolean}
     */
    get supportsStoredPayments() {
        if( sessionStorage["settings.supportsStoredPayments"] === undefined ){
            if( !this.checkoutInfo.supportedPaymentTypes ){
                return false;
            } else {
                var spt = !!(this.checkoutInfo.supportedPaymentTypes.includes('card') || this.checkoutInfo.supportedPaymentTypes.includes('debit'));
                sessionStorage.setItem("settings.supportsStoredPayments", (spt ? 1 : 0));
            }
        }
        return parseInt(sessionStorage["settings.supportsStoredPayments"]) > 0;
    }

    /**
     * @type {?boolean}
     */
    get willBeContactable() {
        if(this.contact) {
            return true
        } else {
            return this.isOpen
        }
    }

    /**
     *
     * @param {string} n
     * @param {*} v
     * @returns {boolean}
     */
    cacheFlag(n, v) {
        sessionStorage[`settings.${n}`] = +v
        return v
    }

    /**
     * True if all the fields appear to be loaded
     *
     * @param {string[]} fields
     * @returns {boolean}
     */
    mayBeLoaded(...fields) {
        return fields.every(f => this[f] !== null)
    }

    async getTicketCount() {
        const ticketStatusOptions = await this.getTicketStatusOptions();
        let statusClosed = null
        if(ticketStatusOptions){
            statusClosed = ticketStatusOptions.find(s => { return s.name=="Closed" })?.id
        }

        const t = await $.ajax({ url: `/a/customer_support_ticket` })
        if(t?.ticketList){
            const filteredTicketList = t.ticketList.filter(item => {
                return item.information.statusId !== statusClosed
            })
            return filteredTicketList.length
        }
        return 0;
    }

    async getTicketStatusOptions() {
        const ticketConfiguration = await $.ajax({
            url: `/a/customer_support_ticket_configuration`
        })

        if(ticketConfiguration?.ticketStatusOptions){
            return ticketConfiguration.ticketStatusOptions.sort(
                (a,b) => { return a.sort_order-b.sort_order; }
            )
        }
    }
}
